<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import Multiselect from "vue-multiselect";
import store from "@/state/store";

export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    const permissions = store.getters["auth/permissions"];
    if (
      (userType === 1 || userType === 3) &&
      permissions.includes("SETUP_VARIATIONS")
    ) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Upload Bulk Variation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    //Multiselect,
  },
  mounted() {
    this.getVariationalPayments();
    this.refreshPMY();
    this.getEmployees();
  },
  watch: {
    band: function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (this.band = result));
    },
  },
  methods: {
    resetForm() {
      this.leapp_empid = null;
      this.leapp_leave_type = null;
      this.leapp_start_date = null;
      this.leapp_end_date = null;
      this.$v.$reset();
    },

    selectionLabel({ text }) {
      return `${text}`;
    },

    getVariationalPaymentss() {
      const url = `${this.ROUTES.paymentDefinition}/variational-payments`;
      this.apiGet(url, "Get Variational Payment Error").then((res) => {
        this.payments = [{ value: null, text: "Select Payment" }];
        const { data } = res;
        data.forEach((payment) => {
          this.payments.push({
            value: payment.pd_id,
            text: payment.pd_payment_name,
          });
        });
      });
    },

    getVariationalPayments() {
      const url = `${this.ROUTES.paymentDefinition}/variational-payments`;
      this.apiGet(url, "Get Variational Payment Error").then((res) => {
        this.paymentsFields = [];
        const { data } = res;
        data.forEach((payment) => {
          this.paymentsFields.push({
            id: payment.pd_id,
            paymentDefinition: payment.pd_payment_name,
            amount: 0,
          });
        });
      });
    },

    refreshPMY() {
      this.apiGet(
        this.ROUTES.payrollMonthYear,
        "Get Payroll Month & Year Error"
      ).then((res) => {
        if (res.data) {
          const monthArray = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          this.pmySet = true;
          const { pym_year, pym_month } = res.data;
          this.month = pym_month;
          this.monthName = monthArray[parseInt(pym_month) - 1];
          this.year = pym_year;
        } else {
          this.pmySet = false;
        }
      });
    },

    launchFilePicker() {
      this.$refs.file.click();
    },

    routeCurrentVariation() {
      this.$router.push({
        name: "current-variation",
      });
    },
    async submitNew() {
      this.submitted = true;
        const formData = new FormData();
        formData.append('variational_payment_file',this.attachment);
        formData.append('month',this.month);
        formData.append('default_id',this.default_id);
        const url = `${this.ROUTES.variationalPayment}/upload-payment`;
        this.apiPost(url, formData, "Upload bulk variation Error").then((res) => {
          //console.log(res)
          this.apiResponseHandler(res.data);
        });

    },


    filesChange(){
      this.attachment = this.$refs.file.files[0];
    },
  },
  data() {
    return {
      submitting: false,
      attachment: null,
      title: "Upload Bulk Variation",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Upload Bulk Variation",
          active: true,
        },
      ],
      payments: [],
      uploadFiles: [],
      payment: null,
      filledPayments: [],
      paymentsFields: [],
      employees: [],
      amount: 0,
      selectedEmployees: null,
      year: null,
      month: null,
      default_id: 1,
      monthName: null,
      submitted: false,
      pmySet: false,
      count: 0,
      uploadFieldName: "file",
    };
  },
};
</script>
<style>
.file-uploads {
  padding: 3em;
  border: 1px dashed #1cbb8c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
}
.file-detail {
  border: 1px solid #ced4da;
  padding: 0.5em 1em;
  border-radius: 5px;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-spinner type="grow" v-if="apiBusy" class="m-2" variant="success" />
    <div v-else class="row">
      <div class="col-6 offset-3 col-md-6 offset-md-3">
        <div class="card">
          <div class="card-body" v-if="pmySet">
            <form @submit.prevent="submitNew" enctype="multipart/form-data">
              <div class="form-group">
                <label for="month">Month <span class="text-danger">*</span></label>
                <input type="text" placeholder="Month" v-model="monthName" readonly id="month" class="form-control">
              </div>
              <div class="form-group">
                <label for="year">Year <span class="text-danger">*</span></label>
                <input type="text" placeholder="Month" v-model="year" readonly id="year" class="form-control">
              </div>

              <div class="form-group">
                <label for="year">Payment Type <span class="text-danger">*</span></label>
                <select name="paymentType" v-model="default_id" id="paymentType" class="form-control">
                  <option selected disabled>-- Select --</option>
                  <option v-for="(data, index) in paymentsFields"
                          :key="index" :value="data.id">{{ data.paymentDefinition }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="attachment">
                  File <span class="text-danger">*</span>
                </label>
                <input
                  id="attachment"
                  type="file"
                  name="attachment"
                  ref="file"
                  @change="filesChange()"
                  class="form-control-file"
                />
              </div>
              <b-button
                v-if="!submitting"
                class="btn btn-success btn-block mt-4"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-else
                disabled
                class="btn btn-success btn-block mt-4"
                type="submit"
              >
                Submitting...
              </b-button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
